<template>
  <div class="popup_wrap" style="width:500px;">
    <!-- popup_wrap :  style="width:500px; height:600px;" 팝업사이즈 참고 / 개발시 삭제 해주 세요. . -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.CSBL310_M1.001') }}</h1> <!-- 쉬운 Certificate 입력 -->
      <div class="content_box">
        <!-- content_box -->
        <p>
          {{ $t('msg.CSBL310_M1.003') }} <!-- 특정한 양식을 가지고 계시지 않은 경우, 아래 문구를 클릭 하시면 해당 내용의 Certi가 입력 됩니다.  (수정하지 않을 경우, 즉시 발행가능) -->
        </p>
        <table class="tbl_row mt5">
          <colgroup>
            <col width="*">
          </colgroup>
          <tbody>
            <tr v-for="(content, index) in easyContentsList" :key="index">
              <td><a href="#" class="text_decoration" @click.prevent="fnInput(content.easyCont)">{{ content.cdNm }}</a></td>
            </tr>
            <tr>
              <td class="text_decoration">
                <a href="#" class="text_decoration" @click.prevent="fnInput(directText)">Direct B/L Certificate</a>
              </td>
            </tr>
            <tr>
              <td class="text_decoration">
                <a href="#"
                   v-if="memberDetail.userCtrCd !== 'KR'"
                   class="text_decoration"
                   @click.prevent="fnInput(tsCertText)"
                >T/S Cert</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'
import blCertificate from '@/api/rest/trans/blCertificate'
import schedule from '@/api/rest/schedule/schedule'

export default {
  name: 'EasyCertificatePop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          bkgNo: ''
        }
      }
    }
  },
  data: function () {
    return {
      blNo: this.parentInfo.blNo,
      bkgNo: this.parentInfo.bkgNo,
      easyContentsList: [],
      tsCertiContsList: [],
      directText: '',
      tsCertText: '',
      schdLegList: [],
      oldBkgNo: '',
      lang: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
    this.init()
  },
  methods: {
    async init () {
      const result = await blCertificate.getEasyCertificateContents(this.blNo)
      this.easyContentsList = result.data.easyCertiContsList
      this.tsCertiContsList = result.data.tsCertiConts

      await this.initLegInfo(this.bkgNo)

      //TOBE :: 다이렉트 컨텐츠 프론트에서 셋팅
      const directCon = result.data.directCertiConts
      const directLeg = result.data.directCertiContsLeg
      this.directText = this.directContents(directCon, directLeg)
      this.tsCertText = this.tsCertContents()
    },
    async initLegInfo (bkgNo) {
      await schedule.getLegDetail(bkgNo).then(res => {
        const list = res.data
        // console.log('EasyCertificatePop getLegDetail list @@@@@ ', list)

        const len = list !== undefined ? list.length : 0
        if (len > 0) {
          list.forEach((c, idx) => {
            if (this.$ekmtcCommon.isEmpty(c.etd)) {
              c.etd = ''
            }

            if (this.$ekmtcCommon.isEmpty(c.eta)) {
              c.eta = ''
            }

            if (this.$ekmtcCommon.isEmpty(c.polEtbDT)) {
              c.polEtbDT = ''
            }

            const etdDT = this.lang === 'KOR' ? this.$ekmtcCommon.changeDatePattern(c.etd, '.') : this.$ekmtcCommon.changeDatePatternEng(c.etd, '.')
            const etaDT = this.lang === 'KOR' ? this.$ekmtcCommon.changeDatePattern(c.eta, '.') : this.$ekmtcCommon.changeDatePatternEng(c.eta, '.')

            if (idx !== 0) {
              c.polEtbDT = etdDT
            } else {
              c.polEtbDT = this.lang === 'KOR' ? this.$ekmtcCommon.changeDatePattern(c.tmpPolEtb, '.') : this.$ekmtcCommon.changeDatePatternEng(c.tmpPolEtb, '.')
            }

            c.etaDT = etaDT
            c.etdDT = etdDT

            if (this.$ekmtcCommon.isEmpty(c.transitTSTime)) {
              c.transitTSTime = ''
            }
          })

          this.$set(this, 'schdLegList', list)
        } else {
          this.$set(this, 'schdLegList', [])
        }

        // console.log('polNm @@@', list[0].polNm)
        // console.log('podNm @@@ ', list[list.length - 1].podNm)
      })
      this.oldBkgNo = bkgNo
    },
    fnInput (contents) {
      this.$emit('selectFunc', contents)
    },
    //직접 입력 양식(BACK -> FRONT)
    directContents (directCon, directLeg) {
      let contents = ''

       //직접입력 양식(메인)
      contents += 'WE HEREBY CERTIFY THAT THE FOLLOWING CONTAINER WAS NOT HANDLED\n'
      contents += 'IN EACH PORT DURING THEIR JOURNEY\n\n'
      contents += '# VESSEL : M/V ' + directCon.vslNm + '\n\n'
      contents += 'THERE IS NO ACTIVITY OR HANDLING FOR CONTAINERS IN CHINA.\n'
      contents += 'ALL THE SHIPMENT IS FROM ' + directCon.polPortNm + ' TO ' + directCon.podPortNm + ' DIRECT SERVICE.\n\n'
      contents += 'CALLING PORT DETAIL:\n\n'

      if (directLeg.length > 0) {
        directLeg.map((item, index) => {
          contents += (index + 1) + '. ' + item + '\n'
        })
      }
      return contents
    },
    tsCertContents () {
      let contents = ''
      let blNo = ''

      if (!this.blNo.includes('KMTC')) {
        blNo = 'KMTC' + this.blNo
      }

      //직접입력 양식(메인)
      contents += 'VESSEL NAME: ' + this.schdLegList[0].vslNm + 'V.' + this.schdLegList[0].voyNo + '\n'
      contents += 'B/L NO. ' + blNo + '\n\n'
      contents += 'PORT OF LOADING : ' + this.schdLegList[0].polNm + '\n'
      contents += 'PORT OF DISCHARGE : ' + this.schdLegList[this.schdLegList.length - 1].podNm + '\n'
      contents += 'TRANSHIP AT (PORT OF TRANSHIP)\n\n' // + this.schdLegList[0].podNm + '\n\n'  2023.03.02 .WMH 수정
      contents += 'THROUGH BILL OF LADING, CONTAINER (S) WILL NOT INVOLVE ANY COMMERCIAL ACTIVITIES DURING\n'
      contents += 'ENROUTE TO DESTINATION,AND SEAL NUMBER REMAIN UNCHANGED\n'
      contents += 'WE CONFIRM THE VESSEL WILL CALL AT OR PASS THROUGH THE FOLLOWING PORTS ENROUTE TO ' + this.schdLegList[this.schdLegList.length - 1].podNm + '\n\n'

      this.tsCertiContsList.forEach((el, idx) => {
        contents += (idx + 1) + '.' + el.portNm.split(',')[0] + '\n'
      })

      return contents
    }
  }
}
</script>
