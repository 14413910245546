var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.CSBL310_M1.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("p", [_vm._v(" " + _vm._s(_vm.$t("msg.CSBL310_M1.003")) + " ")]),
          _c("table", { staticClass: "tbl_row mt5" }, [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(_vm.easyContentsList, function (content, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "text_decoration",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnInput(content.easyCont)
                            },
                          },
                        },
                        [_vm._v(_vm._s(content.cdNm))]
                      ),
                    ]),
                  ])
                }),
                _c("tr", [
                  _c("td", { staticClass: "text_decoration" }, [
                    _c(
                      "a",
                      {
                        staticClass: "text_decoration",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fnInput(_vm.directText)
                          },
                        },
                      },
                      [_vm._v("Direct B/L Certificate")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "text_decoration" }, [
                    _vm.memberDetail.userCtrCd !== "KR"
                      ? _c(
                          "a",
                          {
                            staticClass: "text_decoration",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnInput(_vm.tsCertText)
                              },
                            },
                          },
                          [_vm._v("T/S Cert")]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "*" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }